import React from 'react';
import { Link } from 'react-router-dom';

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";

const markers = [
  { markerOffset: -15, name: "Lisbon", coordinates: [-9.13333, 38.71667] },
  { markerOffset: 25, name: "Stockholm", coordinates: [18.06871, 59.32938] },
  { markerOffset: 25, name: "Buenos Aires", coordinates: [-58.37723, -34.61315] },
  { markerOffset: 25, name: "Nairobi", coordinates: [36.81667, -1.28333] },
  { markerOffset: 25, name: "Mendoza", coordinates: [-68.82717, -32.89084] },
  { markerOffset: 25, name: "Córdoba", coordinates: [-64.207048, -31.417015] },
  { markerOffset: -15, name: "Quito", coordinates: [-78.52495, -0.22985] },
  { markerOffset: -15, name: "Kigali", coordinates: [30.05885, -1.94995] },
  { markerOffset: 25, name: "Kathmandu", coordinates: [85.3206, 27.70169] },
  { markerOffset: 25, name: "Phnom Penh", coordinates: [104.91601, 11.56245] },
  { markerOffset: -15, name: "Cambridge", coordinates: [0.11667, 52.2] },
  { markerOffset: -15, name: "Glasgow", coordinates: [-4.6895, 55.93464] },
  { markerOffset: -15, name: "Belo Horizonte", coordinates: [-43.93778, -19.92083] }
];



function FeaturesWorld() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-6 md:pb-8">
            <h1 className="h2 mb-4">open-seneca works anywhere</h1>
            <p className="text-xl text-gray-600">Explore where we have been and where we are going.</p>

          </div>
          <div className="lg:mx-64 md:mx-8 sm:mx-1">
            <ComposableMap
            // projection="geoAzimuthalEqualArea"
            >
              <Geographies geography={require('../images/world.geojson')}>
                {({ geographies }) =>
                  geographies
                    .map(geo => (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill="#D6D6DA"
                        stroke="#D6D6DA"
                      />
                    ))
                }
              </Geographies>
              {markers.map(({ name, coordinates, markerOffset }) => (
                <Marker key={name} coordinates={coordinates}>
                  <g className="fill-current text-blue-600">
                    <circle className="pulse-mini" r="6" style={{ "animationDelay": (-1 * Math.random() * 1000).toString() + "ms" }} />
                    <circle r="6" />
                  </g>

                </Marker>
              ))}
            </ComposableMap>
          </div>



        </div>
      </div>
    </section>
  );
}

export default FeaturesWorld;
