import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import Sticky from 'sticky-js';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';

import AirQualityMonitor from './pages/AirQualityMonitor';

import About from './pages/About';
import Resources from './pages/Resources';
import Projects from './pages/Projects';

import CommunityEngagement from './pages/CommunityEngagement';
import PolicyMaking from './pages/PolicyMaking';
import Research from './pages/Research';

import Contact from './pages/Contact';

import Privacy from './pages/Privacy';

import PageNotFound from './pages/PageNotFound';


function App() {

  const location = useLocation();

  useEffect(() => {
    if (window.goatcounter) {
      window.goatcounter.count({
        path: location.pathname + location.search + location.hash,
      });
    }
    AOS.init({
      // once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');
  }, [location]);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route path="/air-quality-monitor">
          <AirQualityMonitor />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
        <Route path="/resources">
          <Resources />
        </Route>

        <Route path="/community-engagement">
          <CommunityEngagement />
        </Route>
        <Route path="/policy-making">
          <PolicyMaking />
        </Route>
        <Route path="/research">
          <Research />
        </Route>

        <Route path="/contact">
          <Contact />
        </Route>

        <Route path="/privacy">
          <Privacy />
        </Route>

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
