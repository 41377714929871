import React from 'react';

function ProjectsIntroPast() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-40">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-8 md:pb-12">
            <h1 className="h1 lg:text-4xl mb-4 text-gray-600 font-red-hat-display font-extrabold">Our past <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400">projects</span></h1>
            <p className="text-lg text-gray-600">
              If you want to learn more about them, please contact us!
            </p>
          </div>

        </div>
      </div>
    </section>
  );
}

export default ProjectsIntroPast;
