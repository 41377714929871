import React from 'react';

function ProjectsListPast() {
  return (
    <section>
      {/* Argentina */}
      <div className="max-w-6xl mx-auto px-6 py-12">
        <div className="max-w-sm mx-auto md:max-w-none bg-gray-100 shadow-lg rounded">
          <div className="flex gap-6 p-12" data-aos="zoom-y-out">
            {/* <div className="w-full text-xl text-gray-400">After winning the C40 Women4Climate Tech Challenge 2021 competition, we are deploying a network of sensors in Lisbon and Stockholm. The implementation is funded by VELUX and supported by C40 Cities Climate Leadership group and the local city councils.</div> */}
            {/* <div className="w-1/3 text-xl text-gray-400">
              <div className="flex gap-2 h-24">
                <div className="flex-1">
                  <img className="max-h-full self-center mx-auto" src={require('../images/velux_logo_web.png')} />
                </div>

                <div className="flex-1">
                  <img className="max-h-full self-center mx-auto" src={require('../images/C40_logo_web.jpg')} />
                </div>
              </div>
            </div> */}
          </div>

          {/* City list */}
          <div className="grid gap-12 md:grid-cols-2 md:col-gap-6 md:row-gap-8 items-start">
            {/* 1st city */}
            <article className="flex flex-col h-full px-12 py-6" data-aos="zoom-y-out">
              <header>
                <figure className="relative h-0 pb-1/1 overflow-hidden translate-z-0 rounded-lg mb-6">
                  <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" src={require('../images/projects-lisbon-01.jpg')} width="352" height="352" alt="" />
                </figure>
                <h3 className="h3 text-center font-bold">
                  Buenos Aires
                </h3>
              </header>
              {/* <footer className="text-sm flex items-center mt-4">
                  <div>
                    <p className="text-xl text-gray-400 mb-8">Working with Lisboa Câmara Municipal and FabLab Lisboa to deploy 55 sensors in May 2021. Follow our social media to learn more!</p>
                  </div>
                </footer> */}

              {/* Logos */}
              {/* <div className="flex gap-2 h-24">
                  <div className="flex-1">
                    <img className="max-h-full self-center mx-auto" src={require('../images/Marca_Lisboa_Vertical_Preferencial_preto.png')} />
                  </div>

                  <div className="flex-1">
                    <img className="max-h-full self-center mx-auto" src={require('../images/fablab_lisboa_logo.jpg')} />
                  </div>
                </div> */}
            </article>

            {/* 2nd city */}
            <article className="flex flex-col h-full px-12 py-6" data-aos="zoom-y-out">
              <header>
                <figure className="relative h-0 pb-1/1 overflow-hidden translate-z-0 rounded-lg mb-6">
                  <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" src={require('../images/projects-stockholm-01.jpg')} width="352" height="352" alt="" />
                </figure>
                <h3 className="h3 text-center font-bold">
                  Mendoza
                </h3>
              </header>
              {/* <footer className="text-sm flex items-center mt-4">
                  <div>
                    <p className="text-xl text-gray-400 mb-8">Working with Stockholms stad, Cykelvänligast, and the Students for Sustainability at KTH to deploy 55 sensors in May 2021. Follow our social media to learn more!</p>
                  </div>
                </footer> */}

              {/* Logos */}
              {/* <div className="flex gap-2 h-24">
                  <div className="flex-1">
                    <img className="max-h-full self-center mx-auto" src={require('../images/stockholm_stads_logo.jpg')} />
                  </div>

                  <div className="flex-1">
                    <img className="max-h-full self-center mx-auto" src={require('../images/cykelvanligast_logo.svg')} />
                  </div>

                  <div className="flex-1">
                    <img className="max-h-full self-center mx-auto" src={require('../images/students_for_sustainability_logo.jpg')} />
                  </div>
                </div> */}
            </article>

            {/* 3rd city */}
            <article className="flex flex-col h-full px-12 py-6" data-aos="zoom-y-out">
              <header>
                <figure className="relative h-0 pb-1/1 overflow-hidden translate-z-0 rounded-lg mb-6">
                  <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" src={require('../images/projects-stockholm-01.jpg')} width="352" height="352" alt="" />
                </figure>
                <h3 className="h3 text-center font-bold">
                  Córdoba
                </h3>
              </header>
              {/* <footer className="text-sm flex items-center mt-4">
                  <div>
                    <p className="text-xl text-gray-400 mb-8">Working with Stockholms stad, Cykelvänligast, and the Students for Sustainability at KTH to deploy 55 sensors in May 2021. Follow our social media to learn more!</p>
                  </div>
                </footer> */}
              {/* Logos */}
              {/* <div className="flex gap-2 h-24">
                  <div className="flex-1">
                    <img className="max-h-full self-center mx-auto" src={require('../images/stockholm_stads_logo.jpg')} />
                  </div>

                  <div className="flex-1">
                    <img className="max-h-full self-center mx-auto" src={require('../images/cykelvanligast_logo.svg')} />
                  </div>

                  <div className="flex-1">
                    <img className="max-h-full self-center mx-auto" src={require('../images/students_for_sustainability_logo.jpg')} />
                  </div>
                </div> */}
            </article>
          </div>
        </div>
      </div>

      {/* Kenya */}
      <div className="max-w-6xl mx-auto px-6 py-12">
        <div className="max-w-sm mx-auto md:max-w-none bg-gray-100 shadow-lg rounded">
          {/* <div className="flex gap-6 p-12" data-aos="zoom-y-out">
            <div className="w-full text-xl text-gray-400">
              <p>We are working with UN-Habitat and the University of Helsinki as part of the Urban Pathways project to increase local capacity in assembling, deploying and maintaining open-seneca sensor networks and provide highly granular air quality data that feeds into a city decision support system to reduce air pollution.</p>
              <p>We will deploy 20 sensors in each city: Kigali (Rwanda), Quito (Ecuador), and Kathmandu (Nepal) in July 2021. Stay tuned for updates!</p></div>
            <div className="w-1/3 text-xl text-gray-400">
              <div className="flex gap-2 h-24">
                <div className="flex-1">
                  <img className="max-h-full self-center mx-auto" src={require('../images/unhabitat-logo.png')} />
                </div>
              </div>
            </div>
          </div> */}

          {/* City list */}
          <div className="grid gap-12 md:grid-cols-2 md:col-gap-6 md:row-gap-8 items-start">
            {/* 1st city */}
            <article className="flex flex-col h-full px-12 py-6" data-aos="zoom-y-out">
              <header>
                <figure className="relative h-0 pb-1/1 overflow-hidden translate-z-0 rounded-lg mb-6">
                  <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" src={require('../images/projects-kigali-01.jpg')} width="352" height="352" alt="Photo by Leoboudv" />
                </figure>
                <h3 className="h3 text-center font-bold">
                  Nairobi
                </h3>
              </header>
              {/* <footer className="text-sm flex items-center mt-4">
                <div>
                  <p className="text-xl text-gray-400 mb-8">Working with Lisboa Câmara Municipal and FabLab Lisboa to deploy 55 sensors in May 2021. Follow our social media to learn more!</p>
                </div>
              </footer>
              <div className="flex gap-2 h-24">
                <div className="flex-1">
                  <img className="max-h-full self-center mx-auto" src={require('../images/Marca_Lisboa_Vertical_Preferencial_preto.png')} />
                </div>
                <div className="flex-1">
                  <img className="max-h-full self-center mx-auto" src={require('../images/fablab_lisboa_logo.jpg')} />
                </div>
              </div> */}
            </article>


          </div>
        </div>
      </div>


      {/* Brazil */}
      <div className="max-w-6xl mx-auto px-6 py-12">
        <div className="max-w-sm mx-auto md:max-w-none bg-gray-100 shadow-lg rounded">
          {/* <div className="flex gap-6 p-12" data-aos="zoom-y-out">
            <div className="w-full text-xl text-gray-400">Working in collaboration with the Accelerator Labs at UNDP Cambodia, we are piloting a network of 21 sensors, combining fixed and mobile units, to monitor air quality in targeted areas of Phnom Penh. The measured air quality data will later be used by local partners to implement awareness raising activities and inform strategic planning and decision-making processes related to improving air quality in the city.</div>
            <div className="w-1/3 text-xl text-gray-400">
              <div className="flex gap-2 h-24">
                <div className="flex-1">
                  <img className="max-h-full self-center mx-auto" src={require('../images/undp-logo.jpg')} />
                </div>
              </div>
            </div>
          </div> */}

          {/* City list */}
          <div className="grid gap-12 md:grid-cols-2 md:col-gap-6 md:row-gap-8 items-start">
            {/* 1st city */}
            <article className="flex flex-col h-full px-12 py-6" data-aos="zoom-y-out">
              <header>
                <figure className="relative h-0 pb-1/1 overflow-hidden translate-z-0 rounded-lg mb-6">
                  <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" src={require('../images/projects-phnom-penh-01.jpg')} width="352" height="352" alt="" />
                </figure>
                <h3 className="h3 text-center font-bold">
                  Belo Horizonte
                </h3>
              </header>
              {/* <footer className="text-sm flex items-center mt-4">
                <div>
                  <p className="text-xl text-gray-400 mb-8">Working with Lisboa Câmara Municipal and FabLab Lisboa to deploy 55 sensors in May 2021. Follow our social media to learn more!</p>
                </div>
              </footer> */}

              {/* Logos */}
              {/* <div className="flex gap-2 h-24">
                <div className="flex-1">
                  <img className="max-h-full self-center mx-auto" src={require('../images/Marca_Lisboa_Vertical_Preferencial_preto.png')} />
                </div>

                <div className="flex-1">
                  <img className="max-h-full self-center mx-auto" src={require('../images/fablab_lisboa_logo.jpg')} />
                </div>
              </div> */}
            </article>

          </div>
        </div>
      </div>

    </section>
  );
}

export default ProjectsListPast;
